<template>
  <div id="servebox">
    <div class="col-lg-12">
      <div class="tab-bar">
        <div
            v-for="(item, n) in orderState"
            :key="`${item.state}_${n}`"
            :class="{ active: item.state == listQuery.state }"
            @click="changePayStateTabBar(item.state)"
        >
                    <span>
                        {{ item.name }}
                        <span v-if="item.showDot" class="dot"></span>
                    </span>
        </div>
      </div>
    </div>
    <!-- 点击帖子管理时显示的内容 -->
    <template v-if="listQuery.state == 1" class="box_body">
      <div class="filter-panel">
        <CSSelect
            style="width: 210px; margin-right: 0;"
        >
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect
            style="width: 210px;"
        >
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="search"
            placeholder="搜索标题/姓名/手机号"
            style="
                        width: 240px;
                        height: 30px;
                        border-radius: 3px;
                        margin: 0 20px 15px 0;
                        border: 1px solid #999;
                        padding-left: 10px;
                        vertical-align: middle;
                        "
        />
        <CSSelect style="margin-right: 30px;">
          <select
              v-model="type"
              :style="{
                                    color: type != '' ? '#000' : '#999',
                                }"
              style="height: 30px; border-radius: 4px; padding-left: 5px;"
          >
            <option value="">全部类型</option>
            <option
                v-for="(item, n) in orderType"
                :key="n"
                :value="n"
                style="color: #000"
            >
              {{ item }}
            </option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0;"
            type="button"
            @click="getInvitation()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">发布时间</th>
              <th class="text-center">类型</th>
              <th class="text-center">标题</th>
              <th class="text-center">内容</th>
              <th class="text-center">查看人数</th>
              <th class="text-center">回复数</th>
              <th class="text-center">姓名</th>
              <th class="text-center">手机号</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-for="(log, index1) in invitationLists" v-if="invitationLists.length > 0">
              <tr :key="index1">
                <td class="text-center">
                  {{ log.createTime || '-' }}
                </td>
                <td class="text-center">
                  {{ orderType[log.type] }}
                </td>
                <td class="text-center">
                  {{ log.title || '-' }}
                </td>
                <td class="text-center" style="width: 50px">
                                    <span class="allow-click" @click="lookContent(log.id)">
                                        {{ viewLogsStatus[`${log.id}`] ? '收起' : '查看' }}
                                        <svg :class="['icon', {'reverse': viewLogsStatus[`${log.id}`]}]"
                                             aria-hidden="true">
                                            <use xlink:href="#icon-menuxiala"></use>
                                        </svg>
                                    </span>
                </td>

                <td class="text-center">{{ log.uvCount }}</td>
                <td class="text-center">
                                <span v-if="log.replyCount > 0" class="allow-click"
                                      @click="lookReplyNumber = log.id;deleteInvitationId = log.id; getReplyNumber();">{{
                                    log.replyCount
                                  }}</span>
                  <span v-else>-</span>
                </td>
                <td class="text-center">{{ log.userName }}</td>
                <td class="text-center">{{ log.userPhone }}</td>
                <td class="text-center">
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li v-if="log.isTop === 1">
                        <a
                            style="width: 100%;"
                            @click="
                                                        invitationId = log.id;
                                                        cancelTopInvitation = true;
                                                    "
                        >取消置顶</a
                        >
                      </li>

                      <li v-if="log.isTop != 1">
                        <a
                            style="width: 100%;"
                            @click="
                                                        invitationId = log.id;
                                                        topInvitation = true;
                                                    "
                        >置顶帖子</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%;"
                            @click="
                                                        deleteInvitationId =
                                                            log.id;
                                                        confirmationDelete = true;
                                                    "
                        >删除帖子</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%;"
                            @click="
                                                        bannedToPost(log.userId)
                                                    "
                        >禁言用户</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr
                  v-if="viewLogsStatus[`${log.id}`]"
                  :key="index1 + '_'"
              >
                <td
                    colspan="11"
                    style="
                                        width: 1400px !important;
                                        height: 120px;
                                        overflow-y: auto;
                                    "
                >
                  <div
                      class="content"
                      v-html="log.content"
                  ></div>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </template>
    <!-- 点击敏感字时显示的内容 -->
    <template v-if="listQuery.state == 2" class="box_body">
      <div class="filter-panel">
        <CSSelect
            style="width: 210px; margin-right: 0;"
        >
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect
            style="width: 210px;"
        >
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="search"
            placeholder="搜索标题/姓名/手机号"
            style="
                        width: 240px;
                        height: 30px;
                        border-radius: 4px;
                        margin-right: 20px;
                        margin-bottom: 15px;
                        border: 1px solid #979797;
                        vertical-align: middle;
                        padding-left: 10px;
                    "
        />
        <CSSelect
            style="margin-right: 30px;"
        >
          <select
              v-model="type"
              :style="{
                                color: type != '' ? '#000' : '#999',
                            }"
              style="height: 30px; border-radius: 4px; padding-left: 5px;"
          >
            <option value="">全部类型</option>
            <option :value="1">帖子</option>
            <option :value="2">回复</option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="height: 30px; width: 50px; padding: 0;"
            type="button"
            @click="getKeyword()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">预警时间</th>
              <th class="text-center">类型</th>
              <th class="text-center">内容</th>
              <th class="text-center">姓名</th>
              <th class="text-center">手机号</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-for="(keywordListItem, index1) in keywordList"
                      v-if="keywordList.length > 0"
            >
              <tr :key="index1">
                <td class="text-center">
                  {{ keywordListItem.warningTime }}
                </td>
                <td class="text-center">
                  {{
                    keywordListItem.type == 1
                        ? "帖子"
                        : "回复"
                  }}
                </td>

                <td class="text-center">
                                    <span class="allow-click" @click="lookContent(keywordListItem.id)">
                                        {{ viewLogsStatus[`${keywordListItem.id}`] ? '收起' : '查看' }}
                                        <svg :class="['icon', {'reverse': viewLogsStatus[`${keywordListItem.id}`]}]"
                                             aria-hidden="true">
                                            <use xlink:href="#icon-menuxiala"></use>
                                        </svg>
                                    </span>
                </td>
                <td class="text-center">
                  {{ keywordListItem.userName }}
                </td>

                <td class="text-center">
                  {{ keywordListItem.userPhone }}
                </td>
                <td class="text-center">
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                            style="width: 100%;"
                            @click="
                                                        cancelWarningPopUpWindows = {
                                                            visible: true,
                                                            id:
                                                                keywordListItem.token,
                                                            type:
                                                                keywordListItem.type,
                                                        }
                                                    "
                        >取消预警</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%;"
                            @click="
                                                        confirmationDelete = true;
                                                        replyId =
                                                            keywordListItem.id;
                                                    "
                        >删除回复</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%;"
                            @click="
                                                        bannedToPost(
                                                            keywordListItem.userId
                                                        )
                                                    "
                        >禁言用户</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr
                  v-if="viewLogsStatus[`${keywordListItem.id}`]"
                  :key="index1 + '_'"
              >
                <td
                    colspan="11"
                    style="
                                        width: 1400px !important;
                                        height: 120px;
                                        overflow-y: auto;
                                    "
                >
                  <div
                      class="content"
                      v-html="keywordListItem.content"
                  ></div>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </template>
    <!-- 点击已删除帖子时显示的内容 -->
    <template v-if="listQuery.state == 3" class="box_body">
      <div class="filter-panel">
        <CSSelect style="width: 210px; margin-right: 0;">
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect style="width: 210px;">
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="search"
            placeholder="搜索标题/姓名/手机号"
            style="
                        width: 240px;
                        height: 30px;
                        border-radius: 4px;
                        margin-right: 20px;
                        border: 1px solid #979797;
                        vertical-align: middle;
                        padding-left: 10px;
                        margin-bottom: 15px;
                    "
        />
        <CSSelect>
          <select v-model="type"
                  :style="{'padding-left': '5px','border-radius': '4px', height: '40px', color: type != '' ? '#000' : '#999',}">
            <option value="">全部类型</option>
            <option
                v-for="(item, n) in orderType"
                :key="n"
                :value="n"
                style="color: #000"
            >
              {{ item }}
            </option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0; margin-left: 10px;"
            type="button"
            @click="getAlreadyInvitation()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">删除时间</th>
              <th class="text-center">类型</th>
              <th class="text-center">标题</th>
              <th class="text-center">内容</th>
              <th class="text-center">查看人数</th>
              <th class="text-center">回复数</th>
              <th class="text-center">姓名</th>
              <th class="text-center">手机号</th>
              <th class="text-center">删除原因</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-for="(deleteCommentItem, index2) in invitationList" v-if="invitationList.length > 0">
              <tr :key="index2">
                <td class="text-center">
                  {{ deleteCommentItem.deleteTime }}
                </td>
                <td class="text-center">
                  {{ orderType[deleteCommentItem.type] }}
                </td>
                <td class="text-center">
                  {{ deleteCommentItem.title }}
                </td>
                <td class="text-center">
                            <span class="allow-click" @click="lookContent(deleteCommentItem.id)">
                                {{ viewLogsStatus[`${deleteCommentItem.id}`] ? '收起' : '查看' }}
                                <svg :class="['icon', {'reverse': viewLogsStatus[`${deleteCommentItem.id}`]}]"
                                     aria-hidden="true">
                                    <use xlink:href="#icon-menuxiala"></use>
                                </svg>
                            </span>
                </td>
                <td class="text-center">
                  {{ deleteCommentItem.uvCount }}
                </td>
                <td class="text-center">
                                <span v-if="deleteCommentItem.replyCount > 0" class="allow-click"
                                      @click="lookReplyNumber = deleteCommentItem.id;deleteInvitationId = deleteCommentItem.id;getReplyNumber(deleteCommentItem.id)">{{
                                    deleteCommentItem.replyCount
                                  }}</span>
                  <span v-else>-</span>
                </td>
                <td class="text-center">
                  {{ deleteCommentItem.userName }}
                </td>
                <td class="text-center">
                  {{ deleteCommentItem.userPhone }}
                </td>
                <td class="text-center">
                                <span v-if="deleteCommentItem.deleteCause" class="allow-click"
                                      @click="lookDeleteWhy();deleteInvitationCause = deleteCommentItem.deleteCause;">查看</span>
                  <template v-else>-</template>
                </td>
                <td class="text-center">
                  <div class="btn-group">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="
                                        recoverInvitation(deleteInvitationId.postId);
                                        deleteInvitationId = deleteCommentItem.id;
                                    "
                    >
                      恢复
                    </button>
                  </div>
                </td>
              </tr>
              <tr
                  v-if="viewLogsStatus[`${deleteCommentItem.id}`]"
                  :key="index2 + '_'"
              >
                <td
                    colspan="11"
                    style="
                                            width: 1400px !important;
                                            height: 120px;
                                            overflow-y: auto;
                                        "
                >
                  <div
                      class="content"
                      v-html="deleteCommentItem.content"
                  ></div>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </template>
    <!-- 点击已删除评论时显示的内容 -->
    <template v-if="listQuery.state == 4" class="box_body">
      <div class="filter-panel">
        <CSSelect
            style="width: 210px; margin-right: 0;"
        >
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect
            style="width: 210px;"
        >
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="search"
            placeholder="搜索标题/姓名/手机号"
            style="
                        width: 240px;
                        height: 30px;
                        border-radius: 4px;
                        margin-right: 20px;
                        margin-bottom: 15px;
                        border: 1px solid #979797;
                        vertical-align: middle;
                        padding-left: 10px;
                    "
        />
        <!-- <CSSelect>
            <select
                style="height: 40px; border-radius: 4px; padding-left: 5px;"
                v-model="type"
                :style="{
                    color: type != '' ? '#000' : '#999',
                }"
            >
                <option value="">全部类型</option>
                <option
                    v-for="(item, n) in orderType"
                    :key="n"
                    :value="n"
                    style="color: #000"
                >
                    {{ item }}
                </option>
            </select>
        </CSSelect> -->
        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0;  margin-left: 10px;"
            type="button"
            @click="getDeleteComment()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">删除时间</th>
              <th class="text-center">内容</th>
              <th class="text-center">姓名</th>
              <th class="text-center">手机号</th>
              <th class="text-center">删除原因</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template
                v-if="deleteCommentList.length > 0"

            >
              <template
                  v-for="(
                                    deleteComment
                                ) in deleteCommentList"

              >
                <tr
                    :key="deleteComment.id"
                >
                  <td class="text-center">
                    {{ deleteComment.deleteTime }}
                  </td>
                  <td class="text-center">
                                        <span class="allow-click" @click="lookContent(deleteComment.id)">
                                            {{ viewLogsStatus[`${deleteComment.id}`] ? '收起' : '查看' }}
                                            <svg :class="['icon', {'reverse': viewLogsStatus[`${deleteComment.id}`]}]"
                                                 aria-hidden="true">
                                                <use xlink:href="#icon-menuxiala"></use>
                                            </svg>
                                        </span>
                  </td>
                  <td class="text-center">
                    {{ deleteComment.userName }}
                  </td>
                  <td class="text-center">
                    {{ deleteComment.userPhone }}
                  </td>
                  <td class="text-center">
                                    <span class="allow-click"
                                          @click="lookdeleteContent();commentDeleteCause =deleteComment.deleteCause;">查看</span>
                  </td>
                  <td class="text-center">
                    <div class="btn-group">
                      <button
                          class="btn btn-primary"
                          type="button"
                          @click="
                                                    recoverUser( deleteComment );
                                                    deleteId = deleteComment.id;
                                                "
                      >
                        恢复
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                    v-if="viewLogsStatus[`${deleteComment.id}`]"
                    :key="deleteComment.id + '_'"
                >
                  <td
                      colspan="11"
                      style="
                                            width: 1400px !important;
                                            height: 120px;
                                            overflow-y: auto;
                                        "
                  >
                    <div
                        class="content"
                    >{{ deleteComment.reply }}
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </template>
    <!-- 点击已禁言用户时显示的内容 -->
    <template v-if="listQuery.state == 5" class="box_body">
      <div class="filter-panel">
        <CSSelect
            style="width: 210px; margin-right: 0;"
        >
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect
            style="width: 210px;"
        >
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="search"
            placeholder="搜索标题/姓名/手机号"
            style="
                        width: 240px;
                        height: 30px;
                        border-radius: 4px;
                        margin-right: 30px;
                        margin-bottom: 15px;
                        border: 1px solid #979797;
                        vertical-align: middle;
                        padding-left: 10px;
                    "
        />

        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0;"
            type="button"
            @click="getBannedToPostUser()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">禁言时间</th>
              <th class="text-center">姓名</th>
              <th class="text-center">手机号</th>
              <th class="text-center">禁言原因</th>
              <th class="text-center">禁言时间</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if="bannedList.length > 0">
              <tr
                  v-for="(item, index) in bannedList"
                  :key="index"
              >
                <td class="text-center">
                  {{ item.blockStartTime }}
                </td>
                <td class="text-center">{{ item.userName }}</td>
                <td class="text-center">
                  {{ item.userPhone }}
                </td>
                <td class="text-center">
                                <span class="allow-click"
                                      @click="lookDeleteTheWhy = true;deleteCause = item.blockCause;">查看</span>
                </td>
                <td class="text-center">{{ item.blockDay === -1 ? "永久" : item.blockDay }}</td>
                <td class="text-center">
                  <div class="btn-group">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="
                                                cancelBannedToPost = true;
                                                currentItemId = item.userId;
                                            "
                    >
                      取消禁言
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </template>

    <!-- 点击回复数的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="replyQuantity"
        dialog-header-class="alert-bg" dialogCancelBtnText="关闭"
        dialogTitle="回复数"
        dialogWidth="1120px"
        @onClose="replyQuantity = false"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px;"
      >
        <div style="margin-bottom: 20px;">
          <input
              placeholder="搜索姓名/手机号/内容"
              style="width: 200px; padding-left: 10px; height: 30px; font-size: 16px; vertical-align: middle;border-radius: 4px; border: 1px solid #999"
              type="text"
          />
          <button class="inquire_" style="vertical-align: middle;" @click="getReplyNumber()">查询</button>
        </div>
        <table
            class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
            style="overflow: visible; border-radius: 4px;"
        >
          <thead>
          <tr>
            <th class="text-center">回复时间</th>
            <th class="text-center">姓名</th>
            <th class="text-center">手机号</th>
            <th class="text-center">内容</th>
            <th class="text-center">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(replyNumberItem, index5) in replyNumberlist"
              :key="index5"
          >
            <td class="text-center">
              {{ replyNumberItem.createIime }}
            </td>
            <td class="text-center">
              {{ replyNumberItem.userName }}
            </td>
            <td class="text-center">
              {{ replyNumberItem.userPhone }}
            </td>
            <td class="text-center">
              {{ replyNumberItem.content }}
            </td>
            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="width: 86px;"
                >
                  <li>
                    <a
                        style="width: 100%;"
                        @click="
                                                    confirmationDelete = true;
                                                    replyId = replyNumberItem.id;
                                                "
                    >删除</a
                    >
                  </li>
                  <li>
                    <a
                        style="width: 100%;"
                        @click="
                                                    bannedToPost(
                                                        replyNumberItem.userId
                                                    )
                                                "
                    >禁言</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </CSDialog>
    <!-- 点击查看已删除帖子的删除原因的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="lookDeleteInvitationTheWhy"
        dialog-header-class="alert-bg" dialogCancelBtnText="关闭"
        dialogTitle="删除原因"
        dialogWidth="580px"
        @onClose="lookDeleteInvitationTheWhy = false"
    >
      <div
          v-for="(item,index) in this.deleteInvitationCause.split('；')"
          :key="index"
          slot="dialog-content"
          style="padding: 10px 30px; text-align: center; font-size: 24px;"
      >

        {{ item }}

      </div>
    </CSDialog>
    <!-- 点击查看已删除评论的删除原因的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="lookDeleteCommentTheWhy"
        dialog-header-class="alert-bg" dialogCancelBtnText="关闭"
        dialogTitle="删除原因"
        dialogWidth="580px"
        @onClose="lookDeleteCommentTheWhy = false"
    >
      <div
          v-for="(item,index) in this.commentDeleteCause.split('；')"
          :key="index"
          slot="dialog-content"
          style="padding: 10px 30px; text-align: center; font-size: 24px;"
      >
        {{ item }}
      </div>
    </CSDialog>
    <!-- 点击查看删除原因的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="lookDeleteTheWhy"
        dialog-header-class="alert-bg" dialogCancelBtnText="关闭"
        dialogTitle="禁言原因"
        dialogWidth="580px"
        @onClose="lookDeleteTheWhy = false"
    >
      <div
          v-for="(item,index) in this.deleteCause.split(',')"
          :key="index"
          slot="dialog-content"
          style="padding: 10px 30px; text-align: center; font-size: 24px;"
      >
        {{ item }}
      </div>
    </CSDialog>
    <!-- 点击预警禁言时的弹出框 -->
    <CSDialog
        :dialogVisible="bannedOptions"
        dialogConfirmBtnText="确定"
        dialogTitle="禁言用户"
        dialogWidth="720px"
        @onClose="bannedOptions = false"
        @onConfirm="warningDetermineBannedToPost"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px;"
      >
        <div>
          <div class="banned_title">禁言原因</div>
          <div style="width: 430px; display: inline-block">
            <div
                v-for="(bannedListItem, index) in bannedsList"
                :key="index"
                :class="[
                                'banned_list',
                                { active: isActive.includes(bannedListItem) },
                            ]"
                style="margin-bottom: 23px; line-height: 35px;"
                @click="showdifference(bannedListItem)"
            >
              {{ bannedListItem }}
            </div>
          </div>
        </div>
        <div class="box_fontsize">
          <span class="lableBox" style="vertical-align: middle;">禁言时长</span>
          <CSSelect
              height="40px"
              iWidth="36px"
              style="width: 200px; font-size: 24px;"
          >
            <select
                v-model="BannedTime"
                :style="{
                                color: BannedTime != '' ? '#000' : '#999',
                            }"
            >
              <option value="">请选择</option>
              <option :value="-1" style="color: #000">永久</option>
              <template v-for="n in 30">
                <option
                    :key="n"
                    :value="n"
                    style="color: #000"
                >
                  {{ n }}天
                </option>
              </template>
            </select>
          </CSSelect>
        </div>
      </div>
    </CSDialog>
    <!-- 二次提示是否删除的弹出框 -->
    <CSDialog
        :dialogTitle="replyId ? '删除回复' : '删除帖子'" :dialogVisible="confirmationDelete"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialogWidth="720px"
        @onClose="confirmationDelete = false;"
        @onConfirm="deleteInvitation"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px;"
      >
        <div class="banned_title">删除原因</div>
        <div style="width: 430px; display: inline-block;">
          <div
              v-for="(item, index) in bannedsList"
              :key="index"
              :class="[
                            'banned_list',
                            {
                                active: checkedDeleteCauseArr.includes(item),
                            },
                        ]"
              style="margin-bottom: 23px;"
              @click="changeCheckedDeleteCause(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 二次提示是否取消预警的弹出框 -->
    <CSDialog
        :dialogVisible="cancelWarningPopUpWindows.visible" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="cancelWarningPopUpWindows.visible = false"
        @onConfirm="cancelWarning"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; text-align: center; font-size: 24px;"
      >
        确定取消预警吗？
      </div>
    </CSDialog>
    <!-- 二次提示是否取消禁言的弹出框 -->
    <CSDialog
        :dialogVisible="cancelBannedToPost" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="cancelBannedToPost = false"
        @onConfirm="cancelUser"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        确定取消禁言吗?
      </div>
    </CSDialog>
    <!-- 二次提示是否恢复已删除帖子的弹出框 -->
    <CSDialog
        :dialogVisible="recoverInvitationPopUpBox" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="recoverInvitationPopUpBox = false"
        @onConfirm="confirmRecoverInvitation"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        确定恢复吗?
      </div>
    </CSDialog>
    <!-- 二次提示是否恢复已删除评论的弹出框 -->
    <CSDialog
        :dialogVisible="recoverPopUpBox" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="recoverPopUpBox = false"
        @onConfirm="notarizeRecover"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        确定恢复吗?
      </div>
    </CSDialog>
    <!-- 二次提示是否置顶帖子的弹出框 -->
    <CSDialog
        :dialogVisible="topInvitation" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="topInvitation = false"
        @onConfirm="stickTops"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        确定置顶吗？
      </div>
    </CSDialog>
    <CSDialog
        :dialogVisible="cancelTopInvitation" dialog-confirm-btn-text="确定"
        dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="cancelTopInvitation = false"
        @onConfirm="cancelStickTops"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; text-align: center; font-size: 24px;"
      >
        确定取消置顶吗？
      </div>
    </CSDialog>

    <ViewModal></ViewModal>
  </div>
</template>

<script>
import {
  adminBlockUrl,
  adminCancelAuditUrl,
  adminDelPostUrl,
  adminDelReplyUrl,
  adminIsTopUrl,
  adminQueryBlockUrl,
  adminQueryDelReplyUrl,
  adminQueryPostlUrl,
  adminQuerySensitiveUrl,
} from "@/requestUrl";

import dayjs from "dayjs";
import ViewModal from "@/components/ViewModal";
import Pagination from "@/components/Pagination.vue";
import {getShowRedDot} from "@/utils/index";
import {RED_DOT_TYPES} from "../../../common/commonConst";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import {STORAGE_KEY} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  name: "CommunityManage",
  components: {
    CSTable,
    ViewModal,
    Pagination,
    CSSelect,
    CSDialog,
  },

  data() {
    return {
      filterHeight: 0,
      regionId: this.$vc.getCurrentRegion().communityId,
      refundBox: false, // 控制退款弹出框的显示与隐藏
      replyQuantity: false, //控制回复数弹出框的显示与影藏
      lookDeleteTheWhy: false, //控制查看删除原因弹出框的显示与影藏
      confirmationDelete: false, //控制是否删除弹出框的显示与影藏
      bannedOptions: false, //控制禁言选择弹出框的显示与影藏
      cancelBannedToPost: false, //控制取消禁言弹出框的显示与影藏
      lookDeleteCommentTheWhy: false, //控制已删除评论删除原因弹出框的显示与影藏
      recoverPopUpBox: false, //控制恢复评论删除原因弹出框的显示与影藏
      recoverInvitationPopUpBox: false, //控制恢复帖子删除原因弹出框的显示与影藏
      lookDeleteInvitationTheWhy: false, //控制已删除帖子恢复帖子弹出框的显示与影藏
      topInvitation: false, //控制置顶帖子弹出框的显示与影藏
      cancelTopInvitation: false, //控制置顶帖子弹出框的显示与影藏
      cancelWarningPopUpWindows: {
        visible: false,
        type: null,
        id: null,
      }, //控制取消预警弹出框的显示与影藏
      showBox: false, //帖子内容div显示与隐藏的
      listQuery: {
        state: 1,
        userInfo: "",
        handler: "",
      },
      isActive: [],
      viewLogsStatus: {},
      // 禁言时长
      BannedTime: "",
      bannedReason: "",
      orderState: [
        {state: 1, name: "帖子管理", showDot: false},
        {state: 2, name: "敏感字/图预警", showDot: false},
        {state: 3, name: "已删除帖子", showDot: false},
        {state: 4, name: "已删除评论", showDot: false},
        {state: 5, name: "已禁言用户", showDot: false},
      ],
      orderType: {
        1: "普通帖子",
        2: "闲物置换",
        // 3: "问答",
        4: "求租转租",
        5: "公告",
        6: "活动",
        7: '红包活动',
        8: '问题求助',
      },

      // 禁言列表的数据
      bannedsList: [
        "涉及敏感、反动等不良信息",
        "无实意字符或乱码等特征的灌水类文本",
        "低俗辱骂的垃圾文本内容",
        "含有推广意向的内容",
        "不合网络规范的淫秽内容",
        "不合国家法规的暴恐、赌博毒品等违法内容",
      ],
      // 查询订单返回的数据
      refundLogs: [],
      // 已退款查询返回的数据
      type: "",
      startTime:
          dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
      //开始时间 必填
      endTime: dayjs().format("YYYY-MM-DD") + " 23:59", //结束时间   必填
      search: "", //用户信息，消费码，商户信息
      invitationLists: [], // 社区后台帖子管理查询返回的数据
      invitationList: [], // 社区后台已删除帖子管理查询返回的数据
      keywordList: [], // 社区后台关键字查询返回的数据
      deleteCommentList: [], // 社区后台已删除评论查询返回的数据
      deleteCause: "", //删除原因内容
      deleteCauses: "", //删除原因内容
      bannedList: [], //后台查询禁言用户返回的数据
      replyNumberlist: [], //后台查询回复数返回的数据
      //  删除的原因
      checkedDeleteCauseArr: [],
      // 帖子id
      invitationId: "",
      // 帖子查看人数id
      lookReplyNumber: "",
      // 帖子id
      currentItemInvitationId: "",
      // 发帖人ID
      currentItemId: "",
      // 恢复帖子人的id
      recoverInvitationId: "",
      // 被删除帖子人的Id
      deleteInvitationId: "",
      // 已删除帖子的删除原因
      deleteInvitationCause: "",

      // 被删除评论人的Id
      deleteId: "",
      // 评论是否被删除
      commentIsDelete: "",
      // 已删除评论的删除原因
      commentDeleteCause: "",
      // 恢复评论人的id
      recoverCommentId: "",
      //被删除评论的postId
      postId: "",

      // 被预警帖子的ID
      warningInvitationId: "",
      // 被预警帖子人ID
      warningInvitationPersonId: "",
      // 回复id
      replyId: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    window.addEventListener("keydown", this.getInvitationDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getInvitationDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getInvitationDown);
  },
  mounted() {
    this.getBannedToPostUser();
    this.getDeleteComment();
    this.getKeyword();
    this.getAlreadyInvitation();
    this.getDeleteComment();
    this.getInvitation();         //PS
    // this.getReplyNumber();
    this.$vc.on(this.$route.path, "dot", "communityPostTabar", (params) => {
      this.$set(this.orderState[params.index], "showDot", params.isShow);
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (page) => {
      this.pageParams.pageNo = page;
      switch (this.listQuery.state) {
        case 1:
          this.getInvitation(page);
          break;
        case 2:
          this.getKeyword(page);
          break;
        case 3:
          this.getAlreadyInvitation(page);
          break;
        case 4:
          this.getDeleteComment(page);
          break;
        case 5:
          this.getBannedToPostUser(page);
          break;
      }
    });
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  updated() {
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
  },
  methods: {
    getInvitationDown(e) {
      if (e.keyCode != 13) {
        return;
      }
      const refreshHandler = {
        1: this.getInvitation,
        2: this.getKeyword,
        3: this.getAlreadyInvitation,
        4: this.getDeleteComment,
        5: this.getBannedToPostUser,
      }
      refreshHandler[this.listQuery.state]();
    },
    showdifference(str) {
      const index = this.isActive.indexOf(str);
      if (index > -1) {
        this.isActive.splice(index, 1);
      } else {
        this.isActive.push(str);
      }
    },
    changePayStateTabBar(state) {
      if (this.listQuery.state != state) {
        this.listQuery.state = state;
        const tabBarHanlder = {
          1: this.getInvitation,
          2: this.getKeyword,
          3: this.getAlreadyInvitation,
          4: this.getDeleteComment,
          5: this.getBannedToPostUser,
        };
        tabBarHanlder[state]();
      }
    },
    // 后台查询帖子管理
    getInvitation(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQueryPostlUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startTime, //查询发布开始时间 必填
            endDate: this.endTime, //查询发布结束时间 必填
            type: this.type, //类型1.普通帖子;2.闲物置换;3.问答;4.求组/转租/拼室友;5.公告;6.活动
            search: this.search, //搜索内容
            isDelete: 1, //删除未删除 0.删除;1.未删除 必填
            pageNo, //页码 不传查询全部
            pageSize, //每页显示条数 默认10
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            let tempData = res.data?.datas || []
            tempData.forEach((val) => {
              this.$set(this.viewLogsStatus, `${val.id}`, true);
            });
            if (pageNo === 1 && res.data.total) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo,
              })
            }

            this.invitationLists = tempData
          });
    },
    // 后台查询已删除帖子管理接口
    getAlreadyInvitation(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(adminQueryPostlUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startTime, //查询发布开始时间 必填
            endDate: this.endTime, //查询发布结束时间 必填
            type: this.type, //类型1.普通帖子;2.闲物置换;3.问答;4.求组/转租/拼室友;5.公告;6.活动
            search: this.search, //搜索内容
            isDelete: 0, //删除未删除 0.删除;1.未删除 必填
            pageNo, //页码 不传查询全部
            pageSize, //每页显示条数 默认10
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (!res.data.datas) {
              res.data.datas = [];
            }
            res.data.datas.forEach((val) => {
              this.$set(this.viewLogsStatus, `${val.id}`, false);
            });
            if (pageNo === 1 && res.data?.datas) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize: pageSize,
                currentPage: pageNo,
              })
            }
            this.invitationList = res.data?.datas || [];
          });
    },
    // 后台敏感字键字查询
    getKeyword(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQuerySensitiveUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startTime, //查询预警开始时间 必填
            endDate: this.endTime, //查询预警结束时间 必填
            type: this.type, //类型1.帖子2.回复
            search: this.search, //搜索内容
            pageNo, //页码 不传查询全部
            pageSize, //每页显示条数 默认10
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (!res.data.datas) {
              res.data.datas = [];
            }
            res.data.datas.forEach((val) => {
              this.$set(this.viewLogsStatus, `${val.id}`, true);
            });
            getShowRedDot(this, RED_DOT_TYPES.SOC_SENSITIVE);
            if (pageNo === 1 && res.data?.datas) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize: pageSize,
                currentPage: pageNo,
              })
            }
            this.keywordList = res.data?.datas || [];
          });
    },
    // prefixZero(num) {
    //   return num.toString().padStart(2, "0");
    // },
    // 后台查询已删除评论
    getDeleteComment(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(adminQueryDelReplyUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startTime, //查询预警开始时间 必填
            endDate: this.endTime, //查询预警结束时间 必填
            search: this.search, //搜索内容
            pageNo, //页码 不传查询全部
            pageSize, //每页显示条数 默认10
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1 && res.data?.datas) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize: pageSize,
                currentPage: pageNo,
              })
            }
            this.deleteCommentList = res.data?.datas || [];
          });
    },
    // 查看删除原因
    lookDeleteWhy() {
      this.lookDeleteInvitationTheWhy = true;
    },
    lookdeleteContent() {
      this.lookDeleteCommentTheWhy = true;
    },
    // 后台查询禁言用户
    getBannedToPostUser(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(adminQueryBlockUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            startDate: this.startTime, //查询禁言开始时间 必填
            endDate: this.endTime, //查询禁言结束时间 必填
            search: this.search, //搜索内容
            pageNo, //页码 不传查询全部
            pageSize, //每页显示条数 默认10
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1 && res.data?.datas) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data?.total || 0,
                pageSize: pageSize,
                currentPage: pageNo,
              })
            }
            this.bannedList = res.data?.datas || [];
          });
    },
    // 禁言用户
    bannedToPost(id) {
      this.bannedOptions = true;
      this.currentItemInvitationId = id;
      this.BannedTime = "";
      this.isActive = [];
    },
    // 预警确定禁言
    warningDetermineBannedToPost() {
      this.$fly
          .post(adminBlockUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            userId: this.currentItemInvitationId, //用户id 必填
            isBlock: 1, //禁言状态1禁言2.取消禁言 必填
            blockCause: this.isActive.toString(), //禁言原因
            blockDay: this.BannedTime, //禁言时长  永久-1
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("禁言成功");
            this.bannedOptions = false;
            this.getInvitation();
          });
    },
    // 取消预警接口
    cancelWarning() {
      this.$fly
          .post(adminCancelAuditUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            type: this.cancelWarningPopUpWindows.type, //类型1.帖子2.回复   必填
            token: this.cancelWarningPopUpWindows.id, //帖子或回复id 必填
            operatorId: this.$vc.getCurrentStaffInfo().id,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.cancelWarningPopUpWindows.visible = false;
            this.$vc.toast("取消预警成功");
          });
    },
    // 恢复被禁言的用户
    recoverUser(target) {
      this.postId = target.postId;
      this.deleteId = target.id;
      this.commentIsDelete = target.isDelete;
      this.commentDeleteCause = target.deleteCause;
      this.recoverPopUpBox = true;
    },
    // 恢复被删除帖子
    recoverInvitation(postId) {
      this.recoverInvitationPopUpBox = true;
    },
    // 确认恢复已删除的帖子
    confirmRecoverInvitation() {
      this.$fly
          .post(adminDelPostUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.deleteInvitationId, //回复id 必填
            isDelete: 1, //0删除1未删除 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.recoverInvitationPopUpBox = false;
            this.$vc.toast("恢复成功");
            this.getInvitation();
          });
    },
    // 确认恢复已删除的评论
    notarizeRecover() {
      var id = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO)).id;
      this.$fly
          .post(adminDelReplyUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            id: this.deleteId, //回复id 必填
            isDelete: 1, //0删除1恢复 必填
            deleteCause: this.commentDeleteCause,//删除原因 必填
            userId: id,//操作人id 必填
            postId: this.postId,//帖子id 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast('恢复评论成功！');
            this.recoverPopUpBox = false;
            this.getDeleteComment();
          });
    },

    // 已删除评论取消禁言的用户
    cancelUser() {
      this.$fly
          .post(adminBlockUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
            userId: this.currentItemId, //用户id 必填
            isBlock: 2, //禁言状态1禁言2.取消禁言 必填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("取消禁言成功");
            this.cancelBannedToPost = false;
            this.getBannedToPostUser();
          });
    },
    // 置顶帖子接口
    stickTops() {
      this.$fly
          .post(adminIsTopUrl, {
            regioCode: this.$vc.getCurrentRegion().code, //区域code
            id: this.invitationId, //帖子id
            isTop: 1, //0不置顶1置顶
          })

          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.topInvitation = false;
            this.getInvitation();
          });
    },
    // 取消置顶帖子接口
    cancelStickTops() {
      this.$fly
          .post(adminIsTopUrl, {
            regioCode: this.$vc.getCurrentRegion().code, //区域code
            id: this.invitationId, //帖子id
            isTop: 0, //0不置顶1置顶
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.cancelTopInvitation = false;
            this.getInvitation();
          });
    },
    /**
     * 添加或者移除删除原因
     * @param {String} cause 原因
     *  */
    changeCheckedDeleteCause(cause) {
      const index = this.checkedDeleteCauseArr.indexOf(cause);
      if (index > -1) {
        this.checkedDeleteCauseArr.splice(index, 1);
      } else {
        this.checkedDeleteCauseArr.push(cause);
      }
    },
    // 删除预警的帖子
    deleteInvitation() {
      if (this.replyId != '') {
        this.$fly
            .post(adminDelReplyUrl, {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              id: this.replyId, //回复id 必填
              isDelete: 0, //0删除1未删除 必填
              postId: this.deleteInvitationId,
              deleteCause: this.checkedDeleteCauseArr.join('；'),
              userId: this.$vc.getCurrentStaffInfo().id,
            })
            .then((res) => {
              if (res.code != 0) {
                return
              }
              this.confirmationDelete = false;
              this.checkedDeleteCauseArr = [];
              this.getKeyword();
            })
      } else {
        this.$fly
            .post(adminDelPostUrl, {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              id: this.deleteInvitationId, //帖子id 必填
              isDelete: 0, //删除未删除 0.删除;1.未删除 必填
              delCause: this.checkedDeleteCauseArr.join('；'),
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.confirmationDelete = false;
              this.checkedDeleteCauseArr = [];
              this.getKeyword();
              this.getInvitation();
            });
      }
    },
    // 后台回复数查询
    getReplyNumber() {
      this.$router.push({
        name: "NumberOfReplies",
        params: {id: this.lookReplyNumber, deleteInvitationId: this.deleteInvitationId}
      });
    },
    // 展开与关闭内容的
    lookContent(orderId) {
      this.$set(
          this.viewLogsStatus,
          `${orderId}`,
          !this.viewLogsStatus[`${orderId}`]
      );
      // this.showBox = !this.showBox;
    },
  },
};
</script>

<style scoped>
#servebox .el-dialog__header {
  padding: 0 !important;
}

#servebox .el-dialog__body {
  padding: 10px 20px 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}

#servebox .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}

#servebox .el-dialog div:not(:last-of-type) {
  margin-bottom: 20px;
}

.refundReason_ .el-dialog__header {
  padding: 0 !important;
}

.refundReason_ .el-dialog div {
  margin-bottom: 40px;
}

.refundReason_ .el-dialog__body {
  padding: 10px 20px 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}

.refundReason_ .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}

.refundReason_ .el-dialog div {
  margin-bottom: 20px;
}

.operation .dropdown-menu {
  transform: translate3d(-40px, 35px, 0px) !important;
  z-index: 9999 !important;
}

.box_body1 .el-card__body {
  padding: 20px 0 0px 0;
}
</style>

<style scoped>
.allow-click .icon {
  font-size: 10px;
}

.reverse {
  transform: rotateZ(-180deg);
  transition: transform .3s;
}

i {
  font-style: normal;
}

a {
  text-decoration: underline;
}

.col-lg-12 > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#servebox .col-lg-12 {
  padding-left: 0;
  margin-bottom: 20px;
}

.nav {
  margin-bottom: 20px;
  padding-left: 30px;
}

.line {
  margin: 0 10px 0 5px !important;
}

.form-group {
  margin-right: 20px;
}

#refundTitle {
  width: 80px !important;
  text-align: right;
  padding-left: 15px;
}

.btn_close {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  margin-right: 20px;
}

.btn_affirm {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}

.el-dialog input {
  width: 80px;
  padding-left: 5px;
  border: 1px solid #999;
  border-radius: 2px;
}

.el-dialog textarea {
  width: 720px;
  height: 200px;
  border: none;
  background-color: #f0f0f0;
  resize: none;
  border-radius: 6px;
}

/* #servebox span {
    margin-right: 40px;
} */
.refundReason_ div {
  margin-bottom: 20px;
}

.refundReason_ div span {
  margin-right: 20px;
}

.el-dialog input::-webkit-input-placeholder {
  color: #999;
}

.addVisitingService {
  position: relative;
  width: 150px;
  height: 30px;
  background-color: #1ab394;
  color: #fff;
  vertical-align: middle;
  padding: 7px 0 15px 25px;
  margin-bottom: 15px;
  border-radius: 6px;
  margin-right: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
}

.el-icon-plus {
  position: absolute;
  top: 7px;
  left: 7px;
  font-weight: 900;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 15px;
}

#servebox .box_body .nav .el-card__body {
  padding: 0px !important;
  padding-bottom: 0;
}

/* .nav .el-card__body {
  padding: 0px !important;
} */
.table-stripped thead {
  /*border-top: 1px solid #dddddd;*/
  height: 50px !important;
}

tbody.text-center {
  padding-bottom: 0 !important;
}

.text-center {
  padding: 15px 0;
  vertical-align: middle;
}

.cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
  margin-left: 50px;
  font-size: 24px;
}

.determine {
  width: 80px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
  margin-left: 30px;
  font-size: 24px;
}

.SecondPrompt {
  font-size: 20px;
  text-align: center;
}

.refunds {
  width: 50px;
  height: 30px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  border: none;
}

td p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.box_body tbody .text-center {
  padding-bottom: 0 !important;
}

.padding_ tbody .text-center {
  padding-bottom: 15px !important;
}

.content {
  background-color: #f0f0f0;
  width: 1600px;
  min-height: 150px;
  margin: 0 auto;
  padding: 10px;
}

.cs-style .modal-inner-table {
  font-size: 20px;
}

#servebox .el-dialog__footer {
  border-top: 1px solid #f0f0f0;
  text-align: center !important;
}

.cs-style .modal-inner-table th,
.cs-style .modal-inner-table td {
  min-width: 144px;
}

.seek_ {
  width: 180px !important;
  height: 30px;
  border-radius: 5px !important;
  border: 1px solid #f0f0f0;
}

.inquire_ {
  width: 50px;
  height: 30px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.2);
  border-radius: 4px;
  border: none;
  color: #fff;
  margin-left: 30px;
}

.title_ {
  display: inline-block;
  font-size: 24px;
  margin-right: 47px;
}

.comment_ {
  display: inline-block;
  font-size: 24px;
}

.secondary_ {
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.banned_title {
  font-size: 24px;
  margin-right: 30px;
  margin-left: 20px;
  display: inline-block;
  vertical-align: top;
}

.banned_list {
  width: 480px;
  height: 40px;
  font-size: 24px;
  color: #1ab394;
  border: 1px solid #1ab394;
  display: inline-block;
  border-radius: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.lableBox {
  font-size: 24px;
  margin-left: 20px;
  margin-right: 30px !important;
}

.inputwidth select {
  width: 165px;
  height: 40px;
  font-size: 24px;
  padding-left: 5px;
  border-radius: 3px;
}

.active {
  background-color: #1ab394;
  color: #fff;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
